import React, {useEffect, useState, useRef} from 'react';
import './style.css';
import {Manager, Reference, Popper} from 'react-popper';
import Counter from '../../components/counter';
//import PeopleIcon from './peopleIcon';
import ReactDOM from 'react-dom';
import ClickAway from '@material-ui/core/ClickAwayListener';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from "@material-ui/core/Collapse";
import Fade from '@material-ui/core/Fade';
import nanoid from 'nanoid';
import {disable} from "../../api";




const Nights = (props) => {
    //props
    const {
        class_icon= "icon-place-localizer",
        initData = [],
        callback = () => {
        },
        defData = '',
        textLabel = '',
        textPopper = '',
        id = '',
        name='',
        lang,
        disabled
    } = props;
    //state
    const [data, setData] = useState(initData);
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState(defData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    //ref
    //effect
    useEffect(()=>setData(initData),[initData]);
    //function

    const handleClick = (e) => {
        if (e.target.closest('.form-group') && !disabled) {
            setOpen(!open);
        }
    };
    const handleCountNights = e => {
        callback(e.target.value);
    };


    if(!fullScreen) {

        return (

            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}
                             onClick={handleClick}
                             className={`form-group`}>
                            <label htmlFor={`search_from__${id}`}>
                                <span className={`icon ${class_icon} mr-1`} ></span>
                                {textLabel}
                            </label>

                            <input autoComplete="off"
                                   id={`search_from__${id}`}
                                   type="text"
                                   name={id}
                                   readOnly
                                   className="form-control bg-white"
                                   placeholder={textPopper}
                                   value={defData}
                                   disabled={data.length > 0 && false}
                                   onChange={() => {
                                   }}
                            />


                        </div>
                    )}
                </Reference>
                {
                    open &&
                    ReactDOM.createPortal(
                        <Popper placement="bottom-end">
                            {({ref, style, placement}) => (
                                <div ref={ref} style={style} data-placement={placement}>
                                    <ClickAway onClickAway={() => setOpen(false)}>
                                        <div className={'card card-shadow card-border'} style={{overflow:'hidden'}}>
                                            <div className="bg-white">
                                                <div className="search_form__counter_content">
                                                    <div className={'search_form__counter_item'}>
                                                        <label htmlFor="" className={'search_form__counter_item_label mb-0'}>
                                                            <span>Ночей</span>
                                                            <small>Укажите желаемое количество ночей пребывания</small>
                                                        </label>
                                                        <select name="" multiple={false} id="" className={"w-auto"} onChange={handleCountNights} value={Array.isArray(defData) ? defData[0] : defData }>
                                                            {
                                                                initData.map((item,index)=>
                                                                    <option value={item} key={`opt-${item}`} >
                                                                        {item}
                                                                    </option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ClickAway>
                                </div>
                            )}
                        </Popper>,document.querySelector('#portal-form'))
                }
            </Manager>

        );
    }
    else return <>
        <div
            onClick={handleClick}
            className={`form-group`}>
            <label htmlFor={`search_from__${id}`}>
                <span className={`icon ${class_icon} mr-1`}></span>
                {textLabel}
            </label>

            <input autoComplete="off"
                   id={`search_from__${id}`}
                   type="text"
                   name={id}
                   readOnly
                   className="form-control bg-white"
                   placeholder={textPopper}
                   value={defData}
                   disabled={data.length > 0 && false}
                   onChange={() => {}}
            />


        </div>
        <Dialog open={open} fullScreen={fullScreen}>
            <div className={'search_form__dialog_content'}>
                <div className="search_form__inputModal_inputText">{textPopper}
                    <CloseIcon onClick ={()=>setOpen(!open)}/>
                </div>
                <div className={'search_form__counter_container'}>

                    <div className="search_form__counter_content">

                        <div className="search_form__counter_content">
                            <div className={'search_form__counter_item'}>
                                <label htmlFor="" className={'search_form__counter_item_label'}>
                                    <span>Ночей</span>
                                    <small>Укажите желаймое количество ночей пребывания</small>
                                </label>

                                <select name="" id="" onChange={handleCountNights} className={"form-control-select w-auto"} value={Array.isArray(defData) ? defData[0] : defData}>
                                {
                                    initData.map((item,index)=>
                                        <option value={item} key={`opt-${item}`} >
                                        {item}
                                    </option>)
                                }
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="search_form__counter_action">
                    <button  className={"btn btn-secondary"} onClick={()=>setOpen(!open)}>Готово</button>
                </div>
            </div>
        </Dialog>
    </>
};

export default Nights;
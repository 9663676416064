import React, {useState, useEffect} from 'react';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import axios from 'axios';
import {hydrate, render} from "react-dom";
import qs from 'qs';


window.sleep = function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};


window.initMainSearchForm = function initApp(elem, nameForm, two_calendar, lang, request_options) {
    const rootElement = document.getElementById(elem);
    if (rootElement.hasChildNodes()) {
        hydrate(<App nameForm={nameForm} two_calendar={two_calendar} lang={lang}
                     request_options={request_options}/>, rootElement);
    } else {
        render(<App nameForm={nameForm} two_calendar={two_calendar} lang={lang}
                    request_options={request_options}/>, rootElement);
    }
};
// document.onreadystatechange = function () {
//         if (document.readyState == "complete") {
//             window.initMainSearchForm(
//                 'packagetours',
//                 'packagetours',
//                 true,
//                 {
//                     direction: {
//                         label: "Направление",
//                         popper: "Куда вы хотите поехать?"
//                     },
//
//                     tour: {label: "Тур", popper: "Укажите тур"},
//                     date: {label: "Начало тура", popper: "Укажите дату выезда"},
//                     date_to: {label: "Конец тура", popper: "Укажите дату выезда"},
//                     error:'По данному направлению туров нет',
//                     pessangers: {
//                         label: "Пассажиров",
//                         popper: "Укажите количество пассажиров",
//                         description: {
//                             adults: {label: "Взрослых", description: "от 12 лет"},
//                             children: {label: "Детей", description: "до 12 лет"}
//                         }
//                     },
//                     submit: {label: "Найти"},
//                 },
//                 {
//                     default_url: "/tours/?",
//                     date_url_param: "travelbooking[date_from]",
//                     date_to_url_param: "travelbooking[date_to]",
//                     adults_url_param: "travelbooking[adults]",
//                     children_url_param: "travelbooking[children]",
//                     children_age_url_param: "travelbooking[children_age][]",
//                     url_direction: "http://test.travellab.by/local/components/travelsoft/booking.search_form/templates/packagetours/ajax/directions.php",
//                     date_url: "http://test.travellab.by/local/components/travelsoft/booking.search_form/templates/packagetours/ajax/dates.php",
//                 });
//         }
// };
// document.onreadystatechange = function () {
//     if (document.readyState == "complete") {
//         window.initMainSearchForm(
//             'placements',
//             'placements',
//             true,
//             {
//                 placements: {
//                     label: "Отель",
//                     popper: ""
//                 },
//                 error:"В данном отеле свобобных мест нет",
//                 //tour: {label: "Тур", popper: "Укажите тур"},
//                 date: {label: "Дата заезда", popper: "Укажите дату заезда"},
//                 date_to: {label: "Дата выезда", popper: "Укажите дату выезда"},
//                 pessangers: {
//                     label: "Гостей",
//                     popper: "Укажите количество гостей",
//                     description: {
//                         adults: {label: "Взрослых", description: "от 12 лет"},
//                         children: {label: "Детей", description: "до 12 лет"}
//                     }
//                 },
//                 submit: {
//                     label: "Найти"
//                 },
//             },
//             {
//                 default_url: "/tours/?",
//                 date_url_param: "travelbooking[date_from]",
//                 date_to_url_param: "travelbooking[date_to]",
//                 adults_url_param: "travelbooking[adults]",
//                 children_url_param: "travelbooking[children]",
//                 children_age_url_param: "travelbooking[children_age][]",
//                 url_direction: " http://test.travellab.by/local/components/travelsoft/booking.search_form/templates/placements/ajax/placements.php",
//                 date_url: "http://test.travellab.by/local/components/travelsoft/booking.search_form/templates/placements/ajax/dates.php",
//             });
//     }
// };





